import React, { useEffect, useReducer, useState } from "react";
import { ICheckoutGuestDetailsProps } from "./CheckoutGuestDetailsProps";
import {
  CheckoutHeader,
  GuestInfo,
  StyledRow,
  TrackErrorMessage,
} from "./CheckoutGuestDetailsHelpers";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  validateMaxLengthMessage,
  validateMinLengthMessage,
} from "../../../utils/validations";
import { setCheckout } from "../../../redux/slices/Checkout/checkout";
import { isEmptyObject } from "../../../services/helpers";
import {
  addPageTypeInGTMDataLayer,
  checkoutStep1GTMDataLayer,
} from "../../../utils/datalayers";
import ReservationCartSummary from "../ReservationCartSummary/ReservationCartSummary";
import CheckoutTotal from "../CheckoutTotal/CheckoutTotal";
import LoginModal from "../LoginModal/LoginModal";
import TermsOfServiceModal from "../TermsOfServiceModal/TermsOfServiceModal";
import CheckoutOffers from "../CheckoutOffers/CheckoutOffers";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { getParagraph } from "../../../utils/paragraphHelpers";
import useMobileDetect from "../../../hooks/useMobileDetect";
import {
  termsLinkPosition,
  nextButton,
  backtoProperty,
} from "./CheckoutGuestDetails.module.scss";
import { useHotels } from "../../../hooks/useHotels";
import { checkGuestUserExist } from "../../../services/loyalty";
import { getLoggedStatus, gotoTab } from "../../../utils/helpers";
import { Storage } from "../../../utils/storage";
import { Constants } from "../../../@types/Constants";
import ErrorMessageAlert from "../ErrorMessageAlert/ErrorMessageAlert";
import { useWebFramed } from "../../../hooks/useWebFramed";

const CheckoutGuestDetails = (props: ICheckoutGuestDetailsProps) => {
  const dispatch = useDispatch();
  const isWebFramed = useWebFramed();
  const checkoutObj = useSelector((state: any) => state.checkout);
  const isMobileOnly = useMobileDetect();
  const storedGuestDetails = checkoutObj && checkoutObj.GuestDetails;
  const profile = useSelector((state: any) => state.member.profile);
  const crmProfile = useSelector((state: any) => state.member.crmProfile);
  const isLoggedIn = useSelector((state: any) => state.member.isLoggedIn);
  const [validated, setValidated] = useState(false);
  const [requireMembership, setRequireMembership] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const checkoutTotal = useSelector((state: any) => state.checkoutTotal);
  const hotels = useHotels();
  const [phoneRequire, setPhoneRequire] = useState(false);
  const [showMemberRateMessage, setShowMemberRateMessage] = useState(false);
  const [isLoading, toggleIsLoading] = useReducer((state) => !state, false);
  const [hotelPath, setHotelPath] = useState(null);
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    formState,
    control,
  } = useForm();
  const required = "This field is required.";
  useEffect(() => {
    const hotel = hotels.filter(
      (h: { crs_code: string }) => h.crs_code == checkoutObj.HotelCode
    );
    if (hotel && hotel.length && hotel[0].path) {
      setHotelPath(hotel[0].path?.alias);
    }
    if (hotel && hotel.length && hotel[0]?.field_phone_require) {
      setPhoneRequire(true);
    }
  }, []);

  const cartData = useStaticQuery(graphql`
    {
      allNodePage(filter: { path: { alias: { eq: "/cart-ad" } } }) {
        nodes {
          title
          relationships {
            field_sections {
              type: __typename
              ...ParagraphCartAd
            }
          }
        }
      }
    }
  `);
  const cartAd = cartData.allNodePage.nodes.length
    ? cartData.allNodePage.nodes[0].relationships.field_sections.map(
        getParagraph
      )
    : [];
  useEffect(() => {
    const formValues: any = {};
    if (!isEmptyObject(storedGuestDetails)) {
      if (storedGuestDetails.firstName) {
        formValues.GivenName = storedGuestDetails.firstName;
      }
      if (storedGuestDetails.lastName) {
        formValues.Surname = storedGuestDetails.lastName;
      }
      if (storedGuestDetails.email) {
        formValues.Email = storedGuestDetails.email;
      }
      if (storedGuestDetails.phoneNumber) {
        formValues.PhoneNumber = storedGuestDetails.phoneNumber;
      }
    } else if (
      isLoggedIn &&
      (!isEmptyObject(profile) || !isEmptyObject(crmProfile))
    ) {
      //const values = getValues();
      if (crmProfile.firstName) {
        formValues.GivenName = crmProfile.firstName;
      }
      if (crmProfile.lastName) {
        formValues.Surname = crmProfile.lastName;
      }
      if (profile.email) {
        formValues.Email = profile.email;
      }
      if (profile.phoneNumber) {
        formValues.PhoneNumber = profile.phoneNumber;
      }
      if (isWebFramed) {
        if (crmProfile.email) {
          formValues.Email = crmProfile.email;
        }
        if (crmProfile.phoneNumber) {
          formValues.PhoneNumber = crmProfile.phoneNumber;
        }
      }
    }

    if (!isEmptyObject(formValues)) {
      setValue("GivenName", formValues.GivenName);
      setValue("Surname", formValues.Surname);
      setValue("Email", formValues.Email);
      setValue("PhoneNumber", formValues.PhoneNumber);
    }
  }, [profile, isLoggedIn, storedGuestDetails, crmProfile]);

  useEffect(() => {
    const hasMemberRates = Object.values(props.rooms).filter(
      (room) => room.rate && room.rate.rateType === "member"
    ).length;
    setRequireMembership(hasMemberRates > 0 && !isLoggedIn);
    if (!values.helloRewardsSignup && hasMemberRates > 0) {
      setValue("helloRewardsSignup", true);
    }
  }, [props.rooms, isLoggedIn]);

  useEffect(() => {
    if (props.rooms) {
      const roomsArray = Object.values(props.rooms);
      roomsArray.length > 0 &&
        checkoutStep1GTMDataLayer(
          props.hotel,
          roomsArray,
          props.checkin,
          props.checkout
        );
    }
  }, [props.rooms]);

  useEffect(() => {
    addPageTypeInGTMDataLayer("checkout guest details");
  }, []);
  const handleLoginModal = (show: boolean) => (e: any) => {
    setShowLoginModal(show);
  };

  const handleTermsModalClose = () => {
    setShowTermsModal(false);
  };

  const handleTermsModalShow = () => {
    setShowTermsModal(true);
  };
  const checkUserexist = async (helloRewardsSignup: boolean, email: string) => {
    setShowMemberRateMessage(false);
    if (!isLoggedIn) {
      try {
        const result: any = await checkGuestUserExist(email);
        if (result?.statusCode === "200") {
          setShowMemberRateMessage(true);
          if (helloRewardsSignup) {
            const loginDetails = {
              username: email,
              firstname: values.GivenName,
            };
            Storage.SetLocalStorageValue(
              "Sonesta_login",
              btoa(JSON.stringify(loginDetails))
            );
          }
          return result.userId;
        }
      } catch (err) {
        console.error("error in check user ===== ", err);
        return null;
      }
    } else {
      return false;
    }
  };

  const onSubmit = async (data: any) => {
    toggleIsLoading();
    const userid = await checkUserexist(data.helloRewardsSignup, data.Email);

    if (userid && data.helloRewardsSignup) {
      gotoTab("user-exist-message", isMobileOnly);
      toggleIsLoading();
    } else {
      toggleIsLoading();
      const memberId =
        isLoggedIn && crmProfile && crmProfile.email == data.Email
          ? crmProfile.id
          : userid;
      const guestDetails = {
        firstName: data.GivenName,
        lastName: data.Surname,
        email: data.Email,
        phoneNumber: data.PhoneNumber,
        helloRewardsSignup: data.helloRewardsSignup,
        id: memberId,
        profileType: memberId ? crmProfile.profileType : null,
      };
      const newCheckout = {
        ...checkoutObj,
        GuestDetails: { ...checkoutObj.GuestDetails, ...guestDetails },
      };
      await dispatch(setCheckout(newCheckout));
      setTimeout(() => {
        props.onNextClick && props.onNextClick(newCheckout);
      }, 0);
    }
  };
  const handleHelloRewardsSignup = (event: any) => {
    if (!requireMembership) {
      setShowMemberRateMessage(false);
      setValue("helloRewardsSignup", event.target.checked);
    }
  };
  const handleBacktoProperty = () => {
    navigate(checkoutObj.PropertyPagePath || hotelPath);
  };

  const NextButton = ({
    submit,
  }: {
    submit: React.MouseEventHandler<HTMLButtonElement>;
  }) => (
    <Row className="mt-4 g-0">
      <Col
        xs={{ span: 12, order: 12 }}
        lg={{ span: 6, order: 0 }}
        className="back-rooms-link"
      ></Col>
      <Col lg={{ span: 3, offset: 3 }} className={nextButton}>
        <Button
          type="submit"
          className="w-100 p-2 text-uppercase"
          onClick={submit}
          disabled={isLoading}
          id={"next-checkout"}
        >
          {isLoading ? "Please wait..." : "Next"}
        </Button>
      </Col>
    </Row>
  );
  const values = getValues();

  return (
    <StyledRow>
      <Col>
        <div className={backtoProperty}>
          <span onClick={handleBacktoProperty}>
            <FontAwesomeIcon icon="arrow-left" />
            <div> Back to Property</div>
          </span>
        </div>
        <Row>
          <Col lg={7}>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="pb-4">
                <GuestInfo>
                  {/* <FontAwesomeIcon icon="user-friends" className="me-3" /> */}
                  <p>Guest Details</p>
                </GuestInfo>
                <Form.Row>
                  <Form.Group as={Col} xs={12} lg={6} controlId="givenName">
                    <Form.Label>
                      First Name<span className="star-label">*</span>
                    </Form.Label>
                    <Controller
                      as={<Form.Control isInvalid={errors.GivenName} />}
                      type="text"
                      name="GivenName"
                      control={control}
                      rules={{
                        required: required,
                        maxLength: {
                          value: 50,
                          message: validateMaxLengthMessage(50),
                        },
                      }}
                      defaultValue={values.GivenName || ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      <TrackErrorMessage
                        message={errors.GivenName && errors.GivenName.message}
                        field="First Name"
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} lg={6} controlId="surname">
                    <Form.Label>
                      Last Name<span className="star-label">*</span>
                    </Form.Label>
                    <Controller
                      as={<Form.Control isInvalid={errors.Surname} />}
                      type="text"
                      name="Surname"
                      control={control}
                      rules={{
                        required: required,
                        maxLength: {
                          value: 50,
                          message: validateMaxLengthMessage(50),
                        },
                      }}
                      defaultValue={values.Surname || ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      <TrackErrorMessage
                        message={errors.Surname && errors.Surname.message}
                        field="Last Name"
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} lg={6} controlId="email">
                    <Form.Label>
                      E-mail Address<span className="star-label">*</span>
                    </Form.Label>
                    <Controller
                      as={<Form.Control isInvalid={errors.Email} />}
                      name="Email"
                      type="email"
                      control={control}
                      rules={{
                        required: required,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                          message: "A valid E-mail address is required.",
                        },
                        maxLength: {
                          value: 50,
                          message: validateMaxLengthMessage(50),
                        },
                      }}
                      defaultValue={values.Email || ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      <TrackErrorMessage
                        message={errors.Email && errors.Email.message}
                        field="Email"
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} lg={6} controlId="phoneNumber">
                    <Form.Label>
                      Phone Number{" "}
                      {phoneRequire ? (
                        <span className="star-label">*</span>
                      ) : null}{" "}
                    </Form.Label>
                    <Controller
                      as={
                        <Form.Control
                          isInvalid={errors.PhoneNumber}
                          maxLength={13}
                        />
                      }
                      name="PhoneNumber"
                      type="tel"
                      control={control}
                      rules={{
                        pattern: {
                          //eslint-disable-next-line
                          value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\.0-9]*$/,
                          message: "A valid Phone number is required.",
                        },
                        required: phoneRequire ? required : false,
                        minLength: {
                          value: 10,
                          message: validateMinLengthMessage(10),
                        },
                      }}
                      defaultValue={values.PhoneNumber || ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      <TrackErrorMessage
                        message={
                          errors.PhoneNumber && errors.PhoneNumber.message
                        }
                        field="PhoneNumber"
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {!isLoggedIn && (
                  <>
                    <Row
                      className="g-0 pt-3 pb-0 pb-lg-3 mt-3"
                      id="user-exist-message"
                    >
                      <Col xs={12} lg={8} className="signup-member">
                        <div className="signup-label pb-1">
                          {requireMembership
                            ? `You've Selected a Member Rate`
                            : `Signup for Sonesta Travel Pass`}
                        </div>
                        <Form.Group controlId="helloRewardsSignup">
                          <Controller
                            name="helloRewardsSignup"
                            control={control}
                            render={({ onChange, value }) => (
                              <Form.Check custom>
                                <Form.Check.Input
                                  id="sign-up-in-checkout"
                                  type="checkbox"
                                  tabIndex={0}
                                  checked={value || requireMembership}
                                  onChange={handleHelloRewardsSignup}
                                />
                                <Form.Check.Label>
                                  {requireMembership
                                    ? "You will be enrolled as you book"
                                    : "Sign me up as I book"}
                                  .&nbsp;
                                </Form.Check.Label>
                              </Form.Check>
                            )}
                            type="checkbox"
                            defaultValue={
                              values.helloRewardsSignup || requireMembership
                            }
                          />
                          <label className={termsLinkPosition}>
                            {" "}
                            See
                            <Button
                              variant="link"
                              onClick={handleTermsModalShow}
                            >
                              Terms And Conditions
                            </Button>
                          </label>
                        </Form.Group>
                      </Col>
                      {isMobileOnly ? (
                        <div className="container signInWrapper">
                          <div className="row">
                            <div className="col d-flex align-items-center justify-content-center"></div>
                            <div className="col d-flex align-items-center justify-content-center flex-column">
                              <p className="text-center mb-2 already-member-text">
                                Already a Member?
                              </p>
                              <Button
                                type="button"
                                variant="link"
                                onClick={handleLoginModal(true)}
                                id="checkout-sign-in"
                                className="text-center"
                              >
                                Sign In
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Col xs={12} lg={4} className="already-member">
                          <div className="mt-2 mt-lg-0 px-4 px-lg-0 already-member-text">
                            Already a Member?
                          </div>
                          <Button
                            type="button"
                            variant="link"
                            onClick={handleLoginModal(true)}
                            id="checkout-sign-in"
                          >
                            Sign In
                          </Button>
                        </Col>
                      )}
                    </Row>
                    {showMemberRateMessage && (
                      <ErrorMessageAlert
                        errorType={Constants.ERRORSTYPE.CHECKOUT}
                        errorSubType={
                          Constants.ERRORSSUBTYPE.CHECKOUT.CHECKUSEREXIST
                        }
                        message="An account with that email already exists"
                      >
                        <Row className="g-0 pb-0 pb-lg-3 mb-4">
                          <Alert variant="danger" style={{ fontSize: "15px" }}>
                            An account with that email already exists. Please
                            sign in to proceed with checkout.
                          </Alert>
                        </Row>
                      </ErrorMessageAlert>
                    )}
                  </>
                )}
                <CheckoutOffers
                  rooms={props.rooms}
                  onSubmit={handleSubmit(onSubmit)}
                />
                {!isMobileOnly && (
                  <CheckoutTotal rooms={Object.values(props.rooms)} />
                )}
              </div>
              {!isMobileOnly && <NextButton submit={handleSubmit(onSubmit)} />}
            </Form>
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>
            <ReservationCartSummary />
            {!isWebFramed && <hr style={{ marginTop: "23px" }} />}
            {cartAd.length && !isWebFramed ? cartAd[0] : null}
          </Col>
          <Col lg={{ span: 7, order: 12 }} className="mt-4 mt-lg-0">
            {isMobileOnly && (
              <CheckoutTotal rooms={Object.values(props.rooms)} />
            )}
            {isMobileOnly && <NextButton submit={handleSubmit(onSubmit)} />}
          </Col>
        </Row>
        <LoginModal
          show={showLoginModal}
          onHide={handleLoginModal(false)}
          location={props.location}
        />
        <TermsOfServiceModal
          show={showTermsModal}
          onHide={handleTermsModalClose}
        />
      </Col>
    </StyledRow>
  );
};

export default CheckoutGuestDetails;
