import React, { useEffect, useState } from "react";
import moment from "moment-mini";
import { navigate } from "gatsby";
import LoadingOverlay from "react-loading-overlay";
import { ICheckoutFinishProps } from "./CheckoutFinishProps";
import {
  Container,
  DarkBackground,
  ErrorMessage,
} from "./CheckoutFinishHelpers";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import CheckCheckoutRoomsAvailability from "../CheckCheckoutRoomsAvailability/CheckCheckoutRoomsAvailability";
import { Button, Col, Row } from "react-bootstrap";
import SubTitle from "../../global/SubTitle/SubTitle";
import CheckoutTotal from "../CheckoutTotal/CheckoutTotal";
import CheckoutPayment from "../CheckoutPayment/CheckoutPayment";
import CheckoutAddOns from "../CheckoutAddOns/CheckoutAddOns";
import CheckoutSpecialRequest from "../CheckoutSpecialRequest/CheckoutSpecialRequest";
import CheckoutAgreement from "../CheckoutAgreement/CheckoutAgreement";
import { getToken } from "../../../utils/auth0Client";
import { addCRMProfileCDP } from "../../../redux/slices/Member/member";
import { enrollMember } from "../../../services/loyalty";
import {
  createResRequestJSON,
  createReservation,
  createReservationCBF,
  createReservationPayload,
  cancelInitiatedReservation,
  initiateReservation,
  createInitiateReservationPayload,
  fetchReservationDetails,
} from "../../../services/crs";
import { resParseDateFormat } from "../../../services/dates";
import ReservationCartSummary from "../ReservationCartSummary/ReservationCartSummary";
import { guestServiceProvider } from "../../../services/ServiceProvider";
import {
  addPageTypeInGTMDataLayer,
  checkoutStep2GTMDataLayer,
  resConfirmationGTMDataLayer,
} from "../../../utils/datalayers";
import IReservation from "../../../@types/IReservation";
import { IsUnknown } from "@reduxjs/toolkit/dist/tsHelpers";
import { getLoggedStatus } from "../../../utils/helpers";
import { formatDate, parseSearchDate } from "../../../services/dates";
import { reserveButton } from "./CheckoutFinish.module.scss";
import { useWebFramed } from "../../../hooks/useWebFramed";
import { useHotels } from "../../../hooks/useHotels";
import {
  Trip_Booking_Failure,
  Trip_Booking_Success,
} from "../../../@types/Constants";
import {
  formatFramedWebReservationDetails,
  PostMessage,
} from "../../../utils/webFramed";
import { logout } from "../../../redux/slices/Member/member";
import { signUpCDP } from "../../../utils/auth0Client";
import { setCheckout } from "../../../redux/slices/Checkout/checkout";
import { setInitiateReservationResponse } from "../../../redux/slices/Checkout/initiateReservation";
import CreditCardForm from "../CreditCardForm/CreditCardForm";
import { CheckoutAlert } from "../../../pageHelpers/Checkout/CheckoutHelpers";
import { checkRedemptionRateInCheckout } from "../../../services/redemption";

const CheckoutFinish = (props: ICheckoutFinishProps) => {
  const dispatch = useAppDispatch();
  const isWebFramed = useWebFramed();
  const checkout = useAppSelector((state: any) => state.checkout);
  const initiateReservationResponse = useAppSelector(
    (state: any) => state.initiateReservationResponse
  );
  const recentSearch = useAppSelector((state: any) => state.recentSearch);
  let hotelInfo = {};
  if (isWebFramed) {
    // eslint-disable-next-line
    const data = useHotels().filter(
      (hotel: any) => hotel.crs_code === checkout.HotelCode
    );
    hotelInfo = data.length ? data[0] : {};
  }
  const isRedemptionRate = checkRedemptionRateInCheckout(checkout);
  const checkoutPaymentGiftCardAmount = checkout.Payment
    ? checkout?.Payment?.giftcard_amount
    : null;
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [specialRequest, setSpecialRequest] = useState("");
  const [isCreatingReservation, setIsCreatingReservation] = useState(false);
  const [checkRoomsAvailability, setCheckRoomsAvailability] = useState(false);
  const [allRoomsAvailable, setAllRoomsAvailable] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");
  const [newCCToken, setNewCCToken] = useState(null);
  const [isReservationInProgress, setIsReservationInProgress] = useState(false);
  const [tokenxData, setTokenxData] = useState(null);
  const loggedState = getLoggedStatus();
  const checkInDate = parseSearchDate(checkout.Start);
  const checkOutDate = parseSearchDate(checkout.End);
  const _rooms = Object.values(props.rooms);
  const handleAllRoomsAvailable = () => {
    setAllRoomsAvailable(true);
    setCheckRoomsAvailability(false);
  };
  const handleSelectedRoomsSoldOut = (rooms: Array<any>) => {
    setIsCreatingReservation(false);
    setCheckRoomsAvailability(false);
    props.onSelectedRoomsSoldOut(rooms);
  };
  const handleChangeSpecialRequest = (e: any) => {
    setSpecialRequest(e.target.value);
  };
  const handleCheckAgreeTerms = () => {
    setTermsAgreed(!termsAgreed);
  };
  const onPaymentFailure = (error?: any) => {
    props.onError && props.onError(error);
  };

  const checkGuestUserExist = async (email: string): Promise<void> => {
    // let url;
    // if(process.env.GATSBY_IS_CDP_ENABLED !== "ENABLE"){
    //   url = "/api/checkUserFromGuestWare";
    // }else{
    //   url = "/api/checkUserFromCDP";
    // }
    // const payload = {
    //   email: email,
    // };
    // const response = await fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(payload),
    // });
    // const result = await response.json();
    // result.status = response.status;
    const guestService = await guestServiceProvider();
    const response = await guestService.checkUserByEmail(email);
    const guestDetails = {
      email: checkout?.GuestDetails?.email,
      firstName: checkout?.GuestDetails?.firstName,
      helloRewardsSignup: checkout?.GuestDetails?.helloRewardsSignup,
      id: response?.userByEmail?.userId || null,
      lastName: checkout?.GuestDetails?.lastName,
      phoneNumber: checkout?.GuestDetails?.phoneNumber || null,
      profileType: checkout?.GuestDetails?.profileType,
    };
    await dispatch(
      setCheckout({
        ...checkout,
        GuestDetails: { ...checkout.GuestDetails, ...guestDetails },
      })
    );
    return response;
  };

  const checkUser = async () => {
    await checkGuestUserExist(checkout.GuestDetails.email);
  };

  useEffect(() => {
    checkUser();
  }, []);

  const resubmitReservation = async () => {
    if (!checkout.isResubmitted) {
      dispatch(
        setCheckout({
          ...checkout,
          promotionCode: checkout.groupCode,
          groupCode: null,
          isResubmitted: true,
        })
      );
    }
  };

  useEffect(() => {
    if (checkout.isResubmitted) {
      completeReservation(newCCToken);
    }
  }, [checkout]);

  const handleTokenXResponse = (data: any) => {
    if (data && data.type === "valid") {
      setTokenxData(data);
    } else {
      setIsCreatingReservation(false);
    }
  };

  useEffect(() => {
    if (tokenxData && tokenxData.type === "valid") {
      completeReservation(tokenxData);
    }
  }, [tokenxData]);

  const handleReserveYourStay = () => {
    props.clearError();
    setIsCreatingReservation(true);
    setOverlayMessage("Verifying credit card...");
    // Dev Comments - Commenting Old Reserve your stay Funtionality - This will be replaced by GAPI commit reservation call.
    // props.clearError();
    // setIsCreatingReservation(true);
    // if (!checkout || !checkout.Payment) {
    //   onPaymentFailure(
    //     "Oops! something went wrong. Please reload the page or try again later."
    //   );
    //   setIsCreatingReservation(false);
    //   if (isWebFramed) {
    //     PostMessage({
    //       type: Trip_Booking_Failure,
    //       reservationStatus: "Failed",
    //       message:
    //         "Oops! something went wrong. Please reload the page or try again later.",
    //     });
    //   }
    // } else {
    //   if (!checkout.Payment.ccToken) {
    //     setOverlayMessage("Verifying credit card...");
    //     if (typeof window !== "undefined") {
    //       (window as any).mycheckWallet.getCardToken().then(
    //         (token: any) => {
    //           setNewCCToken(token);
    //           setCheckRoomsAvailability(true);
    //         },
    //         (error: any) => {
    //           const paymentSection = document.getElementById(
    //             "checkoutPaymentSection"
    //           );
    //           paymentSection && paymentSection.scrollIntoView();
    //           const paymentError =
    //             error?.message?.indexOf("Card form invalid") != -1
    //               ? "Card authorization failed."
    //               : error.message;
    //           if (isWebFramed) {
    //             PostMessage({ type: Trip_Booking_Failure });
    //           }
    //           onPaymentFailure(paymentError);
    //           setIsCreatingReservation(false);
    //         }
    //       );
    //     }
    //   } else {
    //     completeReservation();
    //   }
    // }
  };

  const isErrorInReservation = (response: any) => {
    const isError = !!(
      !response ||
      response.error ||
      (response.length && response[0] === null) ||
      (response[0] && response[0].code)
    );

    if (isError) {
      let _errorMsg = `Oops! An unexpected error on the website occurred. Please wait for few moments and then perform your search again. If you receive the same error, please <a href="/contact">Contact</a> our team describing the issue.`;
      if (response && response[0] && response[0].code) {
        switch (response[0].code) {
          case "322":
            _errorMsg =
              "Oh no! Rooms are going fast and your room type is no longer available! Please search again to find the most up to date availability.";
            break;
          case "506":
            _errorMsg = "Hotel does not support this card type.";
            break;
          case "306":
            if (!checkout.isResubmitted) {
              _errorMsg = "Please wait....";
              resubmitReservation();
            } else {
              _errorMsg =
                response[0].message ||
                "A valid Promotional/Corporate Code is required to book this rate.";
              window.dataLayer &&
                window.dataLayer.push({
                  event: "reservation",
                  isResubmitted: checkout?.isResubmitted,
                  isreservationSuccess: false,
                  searchCode: checkout.promotionCode,
                  loggedState: loggedState,
                });
            }
            break;
          case "RL929":
            _errorMsg =
              "Oh No! There was a problem when trying to complete your reservation. Please wait for few moments and then perform your search again.";
            break;
          default:
        }
      }
      props.onError && props.onError(_errorMsg);
    }
    return isError;
  };

  const addtoGTMforresubmissionSuccess = () => {
    window.dataLayer &&
      window.dataLayer.push({
        event: "reservation",
        isResubmitted: checkout?.isResubmitted,
        isreservationSuccess: true,
        searchCode: checkout.promotionCode,
        loggedState: loggedState,
      });
  };

  const completeReservation = async (ccDetails?: any) => {
    setOverlayMessage("Creating reservation...");
    if (
      checkout.GuestDetails.helloRewardsSignup != null &&
      checkout.GuestDetails.helloRewardsSignup
    ) {
      try {
        const guestService = await guestServiceProvider();
        const response = await guestService.signUp(
          checkout.GuestDetails.email,
          checkout.GuestDetails.firstName,
          checkout.GuestDetails.lastName
        );
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error("Error while member signing up.", err.message);
      }
    }
    let newReservationResponses;
    try {
      // const confirmation = await createReservation(request);
      if (
        initiateReservationResponse &&
        initiateReservationResponse?.reservationTransactionId
      ) {
        // Checking if user removed a room on Payment page, If deleted a room, cancelling previous Initiate and creating a new Initiate call
        if (
          Object.keys(checkout.Rooms).length !==
            initiateReservationResponse.roomIds.length ||
          isReservationInProgress
        ) {
          const cancelResponse = cancelInitiatedReservation(
            initiateReservationResponse.reservationTransactionId
          );
          const roomIds = Object.keys(checkout.Rooms);
          const initiateReservationPayload = createInitiateReservationPayload(
            roomIds,
            checkout,
            checkout.GuestDetails.profileType || "Bronze"
          );
          const initiateResponse = await initiateReservation(
            initiateReservationPayload
          );
          const reservationResponses = {
            roomIds: roomIds,
            reservationTransactionId:
              initiateResponse?.reservationTransactionId || "",
          };
          newReservationResponses = reservationResponses;
          dispatch(setInitiateReservationResponse(reservationResponses));
        }
        setIsReservationInProgress(true);
        let checkoutJSON = checkout;

        if (specialRequest) {
          checkoutJSON = {
            ...checkoutJSON,
            SpecialRequests: specialRequest,
          };
        }
        if (newReservationResponses) {
          checkoutJSON = {
            ...checkoutJSON,
            reservationResponses: newReservationResponses,
          };
        }
        const request = createReservationPayload(
          checkoutJSON,
          ccDetails.response
        );
        let totalPointsforReservation: number;
        if (isRedemptionRate) {
          const pointsRequiredPerDay =
            checkoutJSON?.redemptionItem &&
            checkoutJSON?.redemptionItem?.currencyRequired
              ? parseInt(checkoutJSON?.redemptionItem?.currencyRequired)
              : 0;
          const numOfNights = moment(checkoutJSON.End).diff(
            moment(checkoutJSON.Start),
            "days"
          );
          totalPointsforReservation = pointsRequiredPerDay * numOfNights;
        }
        if (initiateReservationResponse?.reservationTransactionId) {
          const reservationTransactionId = newReservationResponses
            ? newReservationResponses.reservationTransactionId
            : initiateReservationResponse.reservationTransactionId;

          const confirmation = await createReservationCBF(
            request,
            reservationTransactionId
          );
          // if (!isErrorInReservation(confirmation)) {
          if (confirmation && confirmation.rooms && confirmation.rooms.length) {
            if (loggedState === "Logged In" && isRedemptionRate) {
              const token = getToken();
              const addCRMProfileAction = addCRMProfileCDP(token);
              dispatch(addCRMProfileAction);
            }
            dispatch(setInitiateReservationResponse({}));
            const getReservationDetails = confirmation.rooms.map(
              async (room: any) => {
                const reservationDetails = await fetchReservationDetails({
                  resId: room.reservationConfirmationId,
                  lastName: checkout.GuestDetails.lastName,
                });
                return reservationDetails.value;
              }
            );
            const reservationConfirmationDetails: Array<any> =
              await Promise.all(getReservationDetails);
            if (checkout.isResubmitted) {
              addtoGTMforresubmissionSuccess();
            }
            const confirmedReservations: Array<any> = [];
            reservationConfirmationDetails.forEach((res: any, i: number) => {
              confirmedReservations.push({
                crs_reservation_id: res.iD,
                arrival: moment(res.start, "YYYY-MM-DD").format(
                  resParseDateFormat
                ),
                departure: moment(res.end, "YYYY-MM-DD").format(
                  resParseDateFormat
                ),
                details: {
                  ...res,
                  Start: res.start,
                  End: res.end,
                  Crs: checkoutJSON.Crs,
                  Policies: null,
                },
                // hotelCode: res.HotelCode,
                lastName: res.guests[0].surname,
                hotelLocation: checkout?.hotel?.hotel_code,
                requiredPoints: totalPointsforReservation,
              });
              if (isWebFramed) {
                res.hotel = hotelInfo;
                res.type = Trip_Booking_Success;
              }
            });
            const details = {
              checkinDate: checkInDate
                ? formatDate(checkInDate, "YYYY-MM-DD")
                : "",
              checkoutDate: checkOutDate
                ? formatDate(checkOutDate, "YYYY-MM-DD")
                : "",
              lengthOfStay:
                (_rooms[0].rate !== null &&
                  _rooms[0].rate.roomRateNightly.length) ||
                "-",
              adults: _rooms[0].adults,
              children: _rooms[0].children,
              totalGuests: _rooms[0].adults + _rooms[0].children,
              rateName: _rooms[0].rate.name,
              rateCode: _rooms[0].rate.RateCode,
              lowestRate: _rooms[0].room.LowestRate,
              isonlyRate: _rooms[0].room.IsonlyRate,
              rateCount: _rooms[0].room.RateCount,
              resEmail: checkout.GuestDetails.email,
            };
            //todo - need to remove confirmatio[0] once we add multi room in mobile app framed view
            if (isWebFramed && reservationConfirmationDetails[0]) {
              const formatReservationDetails =
                reservationConfirmationDetails.map(async (reservation: any) => {
                  const reservationDetails =
                    await formatFramedWebReservationDetails(reservation);
                  reservationDetails.hotel = hotelInfo;
                  reservationDetails.type = Trip_Booking_Success;
                  return reservationDetails;
                });
              const famedWebReservationDetails = await Promise.all(
                formatReservationDetails
              );
              const brand = {
                code: hotelInfo?.relationships?.brand_id?.brand_code,
                name: hotelInfo?.relationships?.brand_id?.name,
              };

              try {
                if (
                  specialRequest?.toLowerCase() !==
                  process.env.TEST_RESERVATION_CODE?.toLowerCase()
                ) {
                  // This will determine whether it is a test reservation or not for mobile app.
                  resConfirmationGTMDataLayer(
                    confirmedReservations,
                    hotelInfo,
                    brand,
                    details,
                    true
                  );
                }
              } catch (err) {
                // eslint-disable-next-line no-console
                console.error("Error setting gtm variables");
              }
              PostMessage({
                type: Trip_Booking_Success,
                payload: {
                  ...famedWebReservationDetails[0],
                  trips: famedWebReservationDetails,
                  isAbandonCart: recentSearch?.isAbandonCart || false,
                },
              });
              dispatch<any>(logout());
            } else {
              navigate("/reservation-confirmation", {
                state: {
                  reservations: confirmedReservations,
                  details,
                },
              });
            }
          } else {
            const commitErrorMessage =
              confirmation && confirmation?.message
                ? ErrorMessage(confirmation?.message)
                : "Your reservation was not confirmed. Please try again or try again later.";
            props.onError && props.onError(commitErrorMessage);
          }
        }
      }
    } catch (err) {
      if (isWebFramed) {
        PostMessage({
          type: Trip_Booking_Failure,
          reservationStatus: "Failed",
          message:
            "Your reservation was not confirmed. Please try again or try again later.",
        });
      }
      const commitErrorMessage =
        err && err?.response?.data?.message
          ? ErrorMessage(err?.response?.data?.message)
          : "Your reservation was not confirmed. Please try again or try again later.";
      props.onError && props.onError(commitErrorMessage);
    }
    setIsCreatingReservation(false);
  };

  React.useEffect(() => {
    if (allRoomsAvailable) {
      completeReservation(newCCToken);
    }
  }, [allRoomsAvailable]);

  React.useEffect(() => {
    if (props.rooms) {
      const roomsArray = Object.values(props.rooms);
      roomsArray.length > 0 &&
        checkoutStep2GTMDataLayer(
          props.hotel,
          roomsArray,
          checkout.Start,
          checkout.End,
          checkout.Payment
        );
    }
  }, [checkoutPaymentGiftCardAmount]);

  React.useEffect(() => {
    addPageTypeInGTMDataLayer("checkout payment details");
  }, []);

  return (
    <Container>
      {checkRoomsAvailability && (
        <CheckCheckoutRoomsAvailability
          onAllRoomsAvailable={handleAllRoomsAvailable}
          onSelectedRoomsSoldOut={handleSelectedRoomsSoldOut}
        />
      )}
      <Row>
        <Col>
          <SubTitle id={"finish-ur-booking"}>Finish your booking</SubTitle>
          <h2>Checkout</h2>
          <h3 className="mt-4  mb-xs-2">{props.hotel.name}</h3>
          <Row>
            <Col lg={{ span: 7, order: 0 }} xs={{ span: 12, order: "last" }}>
              <div id="checkoutPaymentSection">
                {/* <CheckoutPayment
                  className="mt-5"
                  email={checkout.GuestDetails.email}
                /> */}
                <CreditCardForm
                  reservationButtonID={"reserve-your-stay"}
                  handleTokenXResponse={handleTokenXResponse}
                />
              </div>
              {checkout && !isRedemptionRate && <CheckoutAddOns />}
              <Row className="mt-4 mb-4">
                <Col>
                  <CheckoutSpecialRequest
                    setSpecialRequest={handleChangeSpecialRequest}
                  />
                </Col>
              </Row>
              <Row className="my-4">
                <Col>
                  <CheckoutAgreement
                    termsAgreed={termsAgreed}
                    setAgreeTerms={handleCheckAgreeTerms}
                  />
                </Col>
              </Row>
              <CheckoutTotal rooms={Object.values(props.rooms)} />
              <Row className="mt-5">
                {props.showError && props.errorMsg && (
                  <CheckoutAlert handleClearError={props.handleClearError}>
                    {props.errorMsg}
                  </CheckoutAlert>
                )}
                <Col className={reserveButton}>
                  <Button
                    className="w-100 p-2 text-uppercase"
                    disabled={!termsAgreed}
                    onClick={handleReserveYourStay}
                    id="reserve-your-stay"
                  >
                    Reserve your stay
                  </Button>
                </Col>
              </Row>
              <Row className="mt-4 g-0">
                <Col className="text-center">
                  <Button
                    variant="link"
                    className="ps-0"
                    onClick={props.onBackClick}
                    id="back-to-guest-details"
                  >
                    Back to Guest Details
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              lg={{ span: 4, offset: 1, order: 0 }}
              xs={{ span: 12, order: "first" }}
            >
              <ReservationCartSummary />
            </Col>
          </Row>
        </Col>
      </Row>
      <DarkBackground show={isCreatingReservation}>
        <LoadingOverlay
          active={isCreatingReservation}
          spinner={true}
          text={overlayMessage}
        ></LoadingOverlay>
      </DarkBackground>
    </Container>
  );
};

export default CheckoutFinish;
